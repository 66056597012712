$(document).ready(function(){


    // Textarea auto adjust when typing

    $(function(){

        //  changes mouse cursor when highlighting loawer right of box
        $(document).on('mousemove', 'textarea', function(e){
            var a = $(this).offset().top + $(this).outerHeight() - 16,  //  top border of bottom-right-corner-box area
                b = $(this).offset().left + $(this).outerWidth() - 16;  //  left border of bottom-right-corner-box area
            $(this).css({
                cursor: e.pageY > a && e.pageX > b ? 'nw-resize' : ''
            });
        })

        //  the following simple make the textbox "Auto-Expand" as it is typed in
        .on('keyup', 'textarea', function(e){
            //  the following will help the text expand as typing takes place
            while($(this).outerHeight() < this.scrollHeight + parseFloat($(this).css("borderTopWidth")) + parseFloat($(this).css("borderBottomWidth"))){
                $(this).height($(this).height()+1);
            };
        });
    });


    // Sticky Nav show/hide

    function stickyNav(){

        if ($("body").hasClass("home")){

            var windowTop = $(window).scrollTop();
            var bottomSlide = $("#owlCarouselHeader").offset().top + $("#owlCarouselHeader").outerHeight(true);
            var scrollHeight = $(document).height();
            var windowHeight = $(window).height();
            var windowBottom = scrollHeight - (windowTop + windowHeight);

            if((bottomSlide <= windowTop) && (windowBottom >= 100)){
                $(".sticky").addClass("active");
            } else {
                $(".sticky").removeClass("active");
            }

        } else{

            var windowTop = $(window).scrollTop();
            var bottomSlide = $("header").outerHeight(true);
            var scrollHeight = $(document).height();
            var windowHeight = $(window).height();
            var windowBottom = scrollHeight - (windowTop + windowHeight);

            if((bottomSlide <= windowTop) && (windowBottom >= 100)){
                $('.sticky').addClass('active');
            } else {
                $('.sticky').removeClass('active');
            }
        }
    }

    // Sticky form on product page ?



    // Adjust the margin on the home carousel

    function marginSlider(){

        var windowWidth = $( window ).width();
        var marginSliderContent = (windowWidth - 1200)/2;

        if(windowWidth >= 1100){
            $('.contentSlide').css('margin-left', marginSliderContent);
        } else{
            $('.contentSlide').css('margin-left', '0');
        }
    }

    // Display the menu & Change the menu button in close button

    $("#toggle").click(function(){
        $(this).toggleClass("on");
        $('.menu').toggleClass("active");
    });

    // Owl carousel

    $('#owlCarouselHeader').owlCarousel({
        nav: false,
        loop: true,
        items: 1,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        smartSpeed: 1000,
        dots:true,
        mouseDrag:false
    });

    // Restart timer autoplay when translated


    // $('#owlCarouselHeader').on('translated.owl.carousel', function(e){
    //     owl.trigger('stop.owl.autoplay');
    //     owl.trigger('play.owl.autoplay');
    // });
    //
    $('#owlCarouselReassurance').owlCarousel({
        nav: false,
        loop: true,
        items: 1,
        dots: true,
        //autoplay:true,
        //autoplayTimeout:5000,
        autoplayHoverPause:false
    });

    // Owl Carousel in Sync Product

    var sync1 = $("#owlcarousel-ficheProduitBig");
    var sync2 = $("#owlcarousel-ficheProduitSmall");
    var slidesPerPage = 4; //globaly define number of elements per page
    var syncedSecondary = true;

    sync1.owlCarousel({
        items : 1,
        nav: false,
        autoplay: false,
        dots: true,
        loop: true,
        responsiveRefreshRate : 200
    }).on('changed.owl.carousel', syncPosition);

    sync2
        .on('initialized.owl.carousel', function () {
            sync2.find(".owl-item").eq(0).addClass("current");
        })

    .owlCarousel({
        items : slidesPerPage,
        dots: false,
        nav: true,
        margin: 5,
        drag: true,
        slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
        responsiveRefreshRate : 100
    }).on('changed.owl.carousel', syncPosition2);

    function syncPosition(el){
        //if you set loop to false, you have to restore this next line
        //var current = el.item.index;

        //if you disable loop you have to comment this block
        var count = el.item.count-1;
        var current = Math.round(el.item.index - (el.item.count/2) - .5);

        if(current < 0){
          current = count;
        }
        if(current > count){
          current = 0;
        }

        //end block

        sync2
            .find(".owl-item")
            .removeClass("current")
            .eq(current)
            .addClass("current");

        var onscreen = sync2.find('.owl-item.active').length - 1;
        var start = sync2.find('.owl-item.active').first().index();
        var end = sync2.find('.owl-item.active').last().index();

        if (current > end){
            sync2.data('owl.carousel').to(current, 100, true);
        }

        if (current < start){
            sync2.data('owl.carousel').to(current - onscreen, 100, true);
        }
    }

    function syncPosition2(el){
        if(syncedSecondary){
            var number = el.item.index;
            sync1.data('owl.carousel').to(number, 100, true);
        }
    }

    sync2.on("click", ".owl-item", function(e){
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
    });

    // Smooth scroll anchors

    $(function(){
        $("a[href*='#']:not([href='#'])").click(function(){
            if (
                location.hostname == this.hostname
                && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
            ) {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
                if ( anchor.length ) {

                    var anchorfix = anchor.offset().top - 80;
                    $("html, body").animate( { scrollTop: anchorfix }, 750);
                }
            }
        });
    });

    ///////////////////////////////////////////
    //            SELLSY FORMS               //
    //////////////////////////////////////////
    var ref_product = $('#ref_product').attr('data-ref');


    $('#formContact #form_contact').addClass('row');

    // $('#formContact #form_contact').attr('action', '#formContact');

    $('#formContact label').each(function() {
        $(this).hide();
    });

    $('#formContact #contact_form_condition_accept').parent().show();
    $('#formContact #contact_form_condition_accept').parent().css('margin-top','20px');
    $('#formContact #contact_form_condition_accept').parent().css('color','#FFF');
    $('#formContact #contact_form_condition_accept').parent().wrap("<div class='col-xl-12 col-md-12 col-12 sub-form'></div>");

    $('#formContact input[name="contact_form_contact_lastname"]').each(function() {
        $(this).attr("placeholder", "Votre nom *");
    });

    $('#formContact input[name="contact_form_contact_firstname"]').each(function() {
        $(this).attr("placeholder", "Votre prénom");
    });

    $('#formContact input[name="contact_form_contact_email"]').each(function() {
        $(this).attr("placeholder", "Votre email *");
    });

    $('#formContact input[name="contact_form_contact_phone_1"]').each(function() {
        $(this).attr("placeholder", "Votre téléphone");
    });

    $('#formContact input[name="form_cf[70674][simpletext][value]"]').hide();
    $('#formContact input[name="form_cf[70674][simpletext][value]"]').removeAttr('pattern');
    $('#formContact input[name="form_cf[70674][simpletext][value]"]').attr('value', ref_product);

    $('#formContact textarea[name="contact_form_note"]').each(function() {
        $(this).attr("placeholder", "Votre message");
    });

    $('#formContact input[type="text"]').each(function() {
        $(this).addClass('inputText');
        $(this).wrap("<div class='col-md-6 col-12 sub-form'></div>");
    });

    $('#formContact input[type="email"]').each(function() {
        $(this).addClass('inputText');
        $(this).wrap("<div class='col-md-6 col-12 sub-form'></div>");
    });

    $('#formContact textarea').each(function() {
        $(this).addClass('inputText');
        $(this).wrap("<div class='col-12 sub-form'></div>");
    });

    $('#formContact #sellsy_btn_contact').each(function() {
        $(this).addClass('buttonText');
        $(this).wrap("<div class='col-6 containerButton'></div>");
        $(this).wrap("<label for='sellsy_btn_contact'></label>");
        $(this).wrap("<div class='button mylazyload green'></div>");
        $(this).wrap("<div class='buttonContent'></div>");
    });

    $('#formContact .containerButton .green .buttonContent').css('padding', '0px 0px 5px');
    // $('#formContact .containerButton .green .buttonContent #sellsy_btn_contact').before('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 18" style="enable-background:new 0 0 30 18;" xml:space="preserve"><g id="Calque_1"><line class="st0" x1="0.18" y1="8.92" x2="27.93" y2="8.92"/><polyline class="st0" points="20.56,1.03 28.45,8.92 20.52,16.85"/></g><g id="Calque_2"></g></svg>');
    $('#formContact .containerButton .green .buttonContent').after('<span class="buttonLine firstLine"></span>');
    $('#formContact .containerButton .green .buttonContent').after('<span class="buttonLine secondLine"></span>');
    $('#formContact .containerButton .green .buttonContent').after('<span class="buttonLine thirdLine"></span>');
    $('#formContact .containerButton .green .buttonContent').after('<span class="buttonLine fourthLine"></span>');
    $('#formContact .containerButton .green .buttonContent').after('<span class="backgroundButton"></span>');

    $('#formContact .g-recaptcha').each(function() {
        $(this).wrap("<div class='col-12 col-sm-6 sub-form'></div>");
    });



    $('#formContactProduit #form_contact').addClass('row');

    // $('#formContactProduit #form_contact').attr('action', '#formContactProduit');

    $('#formContactProduit label').each(function() {
        $(this).hide();
    });

    $('#formContactProduit #contact_form_condition_accept').parent().show();
    $('#formContactProduit #contact_form_condition_accept').parent().css('margin-top','20px');
    $('#formContactProduit #contact_form_condition_accept').parent().wrap("<div class='col-xl-12 col-md-12 col-12 sub-form'></div>");

    $('#formContactProduit input[name="contact_form_contact_lastname"]').each(function() {
        $(this).attr("placeholder", "Votre nom *");
    });

    $('#formContactProduit input[name="contact_form_contact_firstname"]').each(function() {
        $(this).attr("placeholder", "Votre prénom");
    });

    $('#formContactProduit input[name="contact_form_contact_email"]').each(function() {
        $(this).attr("placeholder", "Votre email *");
    });

    $('#formContactProduit input[name="contact_form_contact_phone_1"]').each(function() {
        $(this).attr("placeholder", "Votre téléphone");
    });

    $('#formContactProduit input[name="form_cf[70674][simpletext][value]"]').hide();
    $('#formContactProduit input[name="form_cf[70674][simpletext][value]"]').removeAttr('pattern');
    $('#formContactProduit input[name="form_cf[70674][simpletext][value]"]').attr('value', ref_product);

    $('#formContactProduit input[type="text"]').each(function() {
        $(this).addClass('inputText');
        $(this).wrap("<div class='col-xl-12 col-md-6 col-12 sub-form'></div>");
    });

    $('#formContactProduit input[type="email"]').each(function() {
        $(this).addClass('inputText');
        $(this).wrap("<div class='col-xl-12 col-md-6 col-12 sub-form'></div>");
    });

    $('#formContactProduit #sellsy_btn_contact').each(function() {
        $(this).addClass('buttonText');
        $(this).wrap("<div class='col-xl-12 col-12 containerButton'></div>");
        $(this).wrap("<label for='sellsy_btn_contact'></label>");
        $(this).wrap("<div class='button blue active'></div>");
        $(this).wrap("<div class='buttonContent'></div>");
    });
    $('#formContactProduit .containerButton .blue .buttonContent').css('padding', '0px 0px 5px');
    // $('#formContactProduit .containerButton .blue .buttonContent #sellsy_btn_contact').before('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 18" style="enable-background:new 0 0 30 18;" xml:space="preserve"><g id="Calque_1"><line class="st0" x1="0.18" y1="8.92" x2="27.93" y2="8.92"/><polyline class="st0" points="20.56,1.03 28.45,8.92 20.52,16.85"/></g><g id="Calque_2"></g></svg>');
    $('#formContactProduit .containerButton .blue .buttonContent').after('<span class="buttonLine firstLine"></span>');
    $('#formContactProduit .containerButton .blue .buttonContent').after('<span class="buttonLine secondLine"></span>');
    $('#formContactProduit .containerButton .blue .buttonContent').after('<span class="buttonLine thirdLine"></span>');
    $('#formContactProduit .containerButton .blue .buttonContent').after('<span class="buttonLine fourthLine"></span>');
    $('#formContactProduit .containerButton .blue .buttonContent').after('<span class="backgroundButton"></span>');

    $('#formContactProduit .g-recaptcha').each(function() {
        $(this).wrap("<div class='col-xl-12 col-md-6 col-12 sub-form'></div>");
    });

    $('.lot_ref').on('click', function() {
        var ref = $(this).attr('data-ref');
        $('#formContact input[name="form_cf[70674][simpletext][value]"]').attr('value', ref);
    });


    /* FORM ALERTE MAIL FOOTER */

    $('#alert_mail #form_contact').addClass('row selectNews');

    // $('#alert_mail #form_contact').attr('action', '#alert_mail');

    $('#alert_mail label').each(function() {
        $(this).hide();
    });

    $('#alert_mail #contact_form_condition_accept').parent().show();
    $('#alert_mail #contact_form_condition_accept').parent().css('margin-top','20px');
    $('#alert_mail #contact_form_condition_accept').parent().wrap("<div class='col-xl-12 col-md-12 col-12 sub-form'></div>");

    $('#alert_mail input[name="contact_form_contact_lastname"]').each(function() {
        $(this).attr("placeholder", "Votre nom *");
    });

    $('#alert_mail input[name="contact_form_contact_firstname"]').each(function() {
        $(this).attr("placeholder", "Votre prénom");
    });

    $('#alert_mail input[name="contact_form_contact_email"]').each(function() {
        $(this).attr("placeholder", "Votre email *");
    });

    $('#alert_mail input[name="contact_form_contact_phone_2"]').each(function() {
        $(this).attr("placeholder", "Votre téléphone");
    });

    $('#alert_mail input[type="text"]').each(function() {
        $(this).addClass('inputText');
        $(this).wrap("<div class='col-md-6 col-12 sub-form'></div>");
        $(this).before('<label for="' + this.id + '">' + this.placeholder + '</label>')
    });


    $('#alert_mail input[type="email"]').each(function() {
        $(this).addClass('mail');
        $(this).addClass('inputText');
        $(this).wrap("<div class='col-md-6 col-12 sub-form'></div>");
        $(this).before('<label for="' + this.id + '">' + this.placeholder + '</label>')
    });

    $('#alert_mail select[name="form_cf[70680][select][value]"]').attr("id", "typeDeBiens_newsletter");
    $('#alert_mail select[name="form_cf[70680][select][value]"]').wrap("<div class='col-md-6 col-12 sub-form'></div>");
    $('#alert_mail select[name="form_cf[70680][select][value]"]').before('<label for="typeDeBiens_newsletter">Sélectionnez un type de biens</label>')
    $('#alert_mail select[name="form_cf[70680][select][value]"]').wrap("<div class='custom-select2'></div>");

    $('#alert_mail select[name="form_cf[67089][select][value]"]').attr("id", "ville_newsletter");
    $('#alert_mail select[name="form_cf[67089][select][value]"]').wrap("<div class='col-md-6 col-12 sub-form'></div>");
    $('#alert_mail select[name="form_cf[67089][select][value]"]').before('<label for="ville_newsletter">Sélectionnez une commune (choix 1)*</label>')
    $('#alert_mail select[name="form_cf[67089][select][value]"]').wrap("<div class='custom-select2'></div>");

    $('#alert_mail select[name="form_cf[67090][select][value]"]').attr("id", "ville2_newsletter");
    $('#alert_mail select[name="form_cf[67090][select][value]"]').wrap("<div class='col-md-6 col-12 sub-form'></div>");
    $('#alert_mail select[name="form_cf[67090][select][value]"]').before('<label for="ville2_newsletter">Sélectionnez une commune (choix 2)</label>')
    $('#alert_mail select[name="form_cf[67090][select][value]"]').wrap("<div class='custom-select2'></div>");

    $('#alert_mail select[name="form_cf[67091][select][value]"]').attr("id", "ville3_newsletter");
    $('#alert_mail select[name="form_cf[67091][select][value]"]').wrap("<div class='col-md-6 col-12 sub-form'></div>");
    $('#alert_mail select[name="form_cf[67091][select][value]"]').before('<label for="ville3_newsletter">Sélectionnez une commune (choix 3)</label>')
    $('#alert_mail select[name="form_cf[67091][select][value]"]').wrap("<div class='custom-select2'></div>");

    $('#alert_mail input[name="contact_form_marketing_email"]').parent().show();
    $('#alert_mail input[name="contact_form_marketing_email"]').next().css('margin-left', '10px');
    $('#alert_mail input[name="contact_form_marketing_email"]').parent().css('margin-top','20px');
    $('#alert_mail input[name="contact_form_marketing_email"]').parent().css('color','#212529');
    $('#alert_mail input[name="contact_form_marketing_email"]').parent().wrap("<div class='col-xl-12 col-md-12 col-12 sub-form'></div>");

    $('#alert_mail #sellsy_btn_contact').each(function() {
        $(this).addClass('buttonText');
        $(this).wrap("<div class='col-md-6 col-12 buttonContaineer'></div>");
        $(this).wrap("<label for='sellsy_btn_contact'></label>");
        $(this).wrap("<div class='button mylazyload blue'></div>");
        $(this).wrap("<div class='buttonContent'></div>");
    });

    $('#alert_mail .buttonContaineer .blue').css('padding', '10px 20px 20px');
    // $('#alert_mail .buttonContaineer .blue .buttonContent #sellsy_btn_contact').before('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 30 18" style="enable-background:new 0 0 30 18;" xml:space="preserve"><g id="Calque_1"><line class="st0" x1="0.18" y1="8.92" x2="27.93" y2="8.92"/><polyline class="st0" points="20.56,1.03 28.45,8.92 20.52,16.85"/></g><g id="Calque_2"></g></svg>');
    $('#alert_mail .buttonContaineer .blue .buttonContent').after('<span class="buttonLine firstLine"></span>');
    $('#alert_mail .buttonContaineer .blue .buttonContent').after('<span class="buttonLine secondLine"></span>');
    $('#alert_mail .buttonContaineer .blue .buttonContent').after('<span class="buttonLine thirdLine"></span>');
    $('#alert_mail .buttonContaineer .blue .buttonContent').after('<span class="buttonLine fourthLine"></span>');
    $('#alert_mail .buttonContaineer .blue .buttonContent').after('<span class="backgroundButton"></span>');


    $('#alert_mail .g-recaptcha').each(function() {
        $(this).wrap("<div class='col-xl-6 col-md-6 col-12 sub-form'></div>");
    });
    // Animation des boutons

    $('.button').each(function(){

        // Parameter
        var animationDuration = 1; // time of the full animation in second

        // get the full lenght of the perimeter
        var btnWidth = $(this).outerWidth();
        var btnHeight = $(this).outerHeight();
        var btnPerimeter = btnWidth*2 + btnHeight*2;

        // Get the time percentage for each width and height
        var durationWidth = (btnWidth/btnPerimeter)*animationDuration;
        var durationHeight = (btnHeight/btnPerimeter)*animationDuration;

        // Apply duration and delay to each line
        if ($(this).hasClass( "active" )){

            $(this).find('.firstLine').css({
                'transition-duration': durationWidth + 's',
                'transition-delay': durationHeight*2 + durationWidth + 's'
            });

            $(this).find('.secondLine').css({
                'transition-duration': durationHeight + 's',
                'transition-delay': durationWidth + durationHeight + 's'
            });

            $(this).find('.thirdLine').css({
                'transition-duration': durationWidth + 's',
                'transition-delay': durationHeight + 's'
            });

            $(this).find('.fourthLine').css({
                'transition-duration': durationHeight + 's',
                'transition-delay': 0 + 's'
            });

        } else{

            $('.firstLine').css({
                'transition-duration': durationWidth + 's',
                'transition-delay': 0 + 's'
            });

            $(this).find('.secondLine').css({
                'transition-duration': durationHeight + 's',
                'transition-delay': durationWidth + 's'
            });

            $(this).find('.thirdLine').css({
                'transition-duration': durationWidth + 's',
                'transition-delay': durationWidth + durationHeight + 's'
            });

            $(this).find('.fourthLine').css({
                'transition-duration': durationHeight + 's',
                'transition-delay': durationWidth*2 + durationHeight + 's'
            });
        }
    });


    // VUE CARTE VUE LISTE ACTIVATION AU CLICK

    $('.vueCarte').click(function() {
        if( !$(this).hasClass( "active" ) ){
            $(this).addClass("active");
            $(".vueListe").removeClass("active");
            $('.containerVue').addClass("vueCarteActive");
            $('.containerVue').removeClass("vueListeActive");
            $('.containerListingMap').show();
            $('.containerListingBiens').hide();
        } else {
        }
    });

    $('.vueListe').click(function() {
        if( !$(this).hasClass( "active" ) ){
            $(this).addClass("active");
            $(".vueCarte").removeClass("active");
            $('.containerVue').addClass("vueListeActive");
            $('.containerVue').removeClass("vueCarteActive");
            $('.containerListingBiens').show();
            $('.containerListingMap').hide();
        } else {
        }
    });

    // BACK TO TOP

    var backToTop = $('.backToTop');

    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            backToTop.addClass('show');
        } else {
            backToTop.removeClass('show');
        }
    });

    backToTop.on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '300');
    });


    stickyNav();
    marginSlider();
    $( window ).resize(marginSlider);
    $( window ).resize(stickyNav);
    $( window ).scroll(stickyNav);

    $.validator.addMethod("validateRecaptcha", function (value, element) {
          if (grecaptcha.getResponse() == '') {
              return false;
          } else {
              return true;
          }
      }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
      if ($('#contactForm').length) {
          $('#checkbox2').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#checkbox2').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#contactForm').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.checkboxAlert');
                error.addClass("checkbox");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".sub-form").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".sub-form").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    $('#formsection1Liens_all').submit(function(event) {

        if ($('#typeDeBiens').val() == 'false') {
            event.preventDefault();
            $('#typeDeBiens').parents(".sub-form").addClass("has-error").removeClass("has-success");
            $('#typeDeBiens').parents(".sub-form").append('<span class="error help-block search_span">Ce champ est obligatoire.</span>')
        } else {
            $('#typeDeBiens').parents(".sub-form").addClass("has-success").removeClass("has-error");
            $('#formsection1Liens_all').unbind().submit()
        }
    });

    $('#ville').select2();
    $('#ville_newsletter').select2();
    $('#ville2_newsletter').select2();
    $('#ville3_newsletter').select2();
    // $('#ville3_newsletter').parent().css('margin-bottom','37px');
    $('#typeDeBiens_newsletter').select2();

    $("#accept_cookies").on("click", function() {
        var e = new Date,
        t = e.getTime() + 31536e6;
        e.setTime(t), console.log(e), document.cookie = "gh_accept_cookies=yes;expires=" + e.toGMTString() + ";path=/", $("#cookies_bar").slideUp(250, function() {
            s(this).remove()
        })
    });

    /** SELLSY VALIDATE FORM DO NOT TOUCH ! **/

    /**1
     * FORM VALIDATE
     */

    // // Prevent double click for sumbit (eg: click not work):
    // var processing = false;
    // // $('#form_contact #sellsy_btn_contact').on('click dblclick',function(e){
    // $("#form_contact #sellsy_btn_contact").click(function(e){
    //     e.stopPropagation();
    //     if(!processing){
    //         $(this).attr("disabled", true).html("Loading ...");
    //         $('#form_contact').submit();
    //     }
    //     processing = true
    // });

});
